import { render, staticRenderFns } from "./role_details.vue?vue&type=template&id=aa33b4fe&scoped=true"
import script from "./role_details.vue?vue&type=script&lang=js"
export * from "./role_details.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa33b4fe",
  null
  
)

export default component.exports