import Vue from "vue";
import VueRouter from "vue-router";
import login from "../components/login/login.vue"; //登录
import home from "../view/home.vue"; //主体框架

import index from "../components/index/index.vue"; //数据看板
import indexList from "../components/index/indexList.vue"; //数据看板

import ulist from "../components/user/user/ulist.vue"; //用户列表
import udetails from "../components/user/user/udetails.vue"; //用户详情
import uadd from "../components/user/user/uadd.vue"; //添加用户
import uchange from "../components/user/user/uchange.vue"; //用户编辑
import upush from "../components/user/user/upush.vue"; //用户推送

import role_list from "../components/admin/sysRole/role_list.vue"; //角色列表
import role_details from "../components/admin/sysRole/role_details.vue"; //角色详情+编辑
import role_add from "../components/admin/sysRole/role_add.vue"; //添加角色
import role_permission from "../components/admin/sysRole/role_permission.vue"; //系统用户权限编辑

import permission_list from "../components/admin/sysPermission/permission_list.vue"; //权限列表
import permission_details from "../components/admin/sysPermission/permission_details.vue"; //权限详情+编辑
import permission_add from "../components/admin/sysPermission/permission_add.vue"; //添加权限

import sysUser_list from "../components/admin/sysUser/sysUser_list.vue"; //系统用户列表
import sysUser_details from "../components/admin/sysUser/sysUser_details.vue"; //系统用户详情+编辑
import sysUser_add from "../components/admin/sysUser/sysUser_add.vue"; //添加系统用户
import institutions_editor from "../components/admin/sysUser/institutions_editor.vue"; //机构信息

import operation_log from "../components/log/operation_log.vue"; //操作日志列表
import login_log from "../components/log/login_log.vue"; //登录日志列表

import label_list from "../components/user/label/label_list.vue"; //标签列表
import label_details from "../components/user/label/label_details.vue"; //标签详情+编辑
import label_add from "../components/user/label/label_add.vue"; //添加标签

import newLabel_list from "../components/user/newLabel/newLabel_list.vue"; //新标签列表
import newLabel_editor1 from "../components/user/newLabel/newLabel_editor1.vue"; //新标签编辑
import newLabel_editor2 from "../components/user/newLabel/newLabel_editor2.vue"; //新标签编辑
import newLabel_editor3 from "../components/user/newLabel/newLabel_editor3.vue"; //新标签编辑
import newLabel_editor4 from "../components/user/newLabel/newLabel_editor4.vue"; //新标签编辑

import release_service_list from "../components/release/service/release_service_list.vue"; //发布服务列表
import release_service_details from "../components/release/service/release_service_details.vue"; //发布服务详情
import release_service_editor from "../components/release/service/release_service_editor.vue"; //发布服务编辑
import release_service_add from "../components/release/service/release_service_add.vue"; //添加服务
import release_service_push from "../components/release/service/release_service_push.vue"; //推送服务

import release_task_list from "../components/release/task/release_task_list.vue"; //发布任务列表
import release_task_details from "../components/release/task/release_task_details.vue"; //发布任务详情
import release_task_editor from "../components/release/task/release_task_editor.vue"; //发布任务编辑
import release_task_add from "../components/release/task/release_task_add.vue"; //添加任务
import release_task_push from "../components/release/task/release_task_push.vue"; //任务推送

import release_problem_list from "../components/release/problem/release_problem_list.vue"; //发布问题列表
import release_problem_details from "../components/release/problem/release_problem_details.vue"; //发布问题详情
import release_problem_editor from "../components/release/problem/release_problem_editor.vue"; //发布问题编辑
import release_problem_add from "../components/release/problem/release_problem_add.vue"; //添加问题
import release_problem_push from "../components/release/problem/release_problem_push.vue"; //推送问题

import release_supply_list from "../components/release/supply/release_supply_list.vue"; //发布供应列表
import release_supply_details from "../components/release/supply/release_supply_details.vue"; //发布供应详情
import release_supply_editor from "../components/release/supply/release_supply_editor.vue"; //发布供应编辑
import release_supply_add from "../components/release/supply/release_supply_add.vue"; //添加供应
import release_supply_push from "../components/release/supply/release_supply_push.vue"; //供应推送
import release_msg from "../components/release/supply/release_msg.vue"; //用户聊天

import release_demand_list from "../components/release/demand/release_demand_list.vue"; //发布采购列表
import release_demand_details from "../components/release/demand/release_demand_details.vue"; //发布采购详情
import release_demand_editor from "../components/release/demand/release_demand_editor.vue"; //发布采购编辑
import release_demand_add from "../components/release/demand/release_demand_add.vue"; //添加采购
import release_demand_push from "../components/release/demand/release_demand_push.vue"; //采购推送

import teaching_list from "../components/teaching/teaching_list.vue"; //教学列表
import teaching_details from "../components/teaching/teaching_details.vue"; //教学详情
import teaching_editor from "../components/teaching/teaching_editor.vue"; //教学编辑
import teaching_add from "../components/teaching/teaching_add.vue"; //添加教学
import teaching_push from "../components/teaching/teaching_push.vue"; //添加教学

import live_list from "../components/live/live_list.vue"; //直播列表
import live_details from "../components/live/live_details.vue"; //教学详情
import live_editor from "../components/live/live_editor.vue"; //教学编辑
import live_add from "../components/live/live_add.vue"; //添加教学

import withdrawal_list from "../components/withdrawal/withdrawal_list.vue"; //提现列表

import push_list from "../components/push/push_list.vue"; //推送列表

import training_list from "../components/training/training/training_list.vue"; //运营-培训讲师管理

import teacher_list from "../components/training/teacher/teacher_list.vue"; //运营-老师列表
import teacher_add from "../components/training/teacher/teacher_add.vue"; //运营-老师添加

// -------------------------------------------------------------------------------------------------------------------------------
import orgHome from "../view/orgHome.vue"; //主体框架

import u_training_list from "../components/u_training/u_training/u_training_list.vue"; //培训列表
import u_training_add from "../components/u_training/u_training/u_training_add.vue"; //培训添加
import u_training_detils from "../components/u_training/u_training/u_training_detils.vue"; //培训详情
import u_training_editor from "../components/u_training/u_training/u_training_editor.vue"; //培训修改
import u_training_user_detils from "../components/u_training/u_training/u_training_user_detils.vue"; //培训学员列表

import u_teacher_list from "../components/u_training/u_teacher/u_teacher_list.vue"; //老师列表
import u_teacher_add from "../components/u_training/u_teacher/u_teacher_add.vue"; //添加老师

import openUser from "../components/openPlatform/openUser.vue"; //开放平台
import openService from "../components/openPlatform/openService.vue"; //开放接口
import openServiceAdd from "../components/openPlatform/openServiceAdd.vue"; //添加开放接口

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: "/", redirect: "/login" },
    { path: "/login", component: login },
    {
      path: "/orgHome",
      component: orgHome,
      redirect: "/u_training_list",
      children: [
        // 机构培训---------------------------------------------------
        {
          path: "/u_training_list",
          component: u_training_list,
        },
        {
          path: "/u_training_add",
          component: u_training_add,
        },
        {
          path: "/u_training_detils",
          component: u_training_detils,
        },
        {
          path: "/u_training_editor",
          component: u_training_editor,
        },
        {
          path: "/u_training_user_detils",
          component: u_training_user_detils,
        },
        // 机构培训-老师---------------------------------------------------
        {
          path: "/u_teacher_list",
          component: u_teacher_list,
        },
        {
          path: "/u_teacher_add",
          component: u_teacher_add,
        },
      ],
    },
    {
      path: "/home",
      component: home,
      redirect: "/index",
      children: [
        // 看板-------------------------------------------------------
        {
          path: "/index",
          component: index,
        },
        {
          path: "/indexList",
          component: indexList,
        },
        // 用户-------------------------------------------------------
        {
          path: "/ulist",
          component: ulist,
        },
        {
          path: "/udetails",
          component: udetails,
        },
        {
          path: "/uadd",
          component: uadd,
        },
        {
          path: "/uchange",
          component: uchange,
        },
        {
          path: "/upush",
          component: upush,
        },
        // 角色-------------------------------------------------------
        {
          path: "/role_list",
          component: role_list,
        },
        {
          path: "/role_details",
          component: role_details,
        },
        {
          path: "/role_add",
          component: role_add,
        },
        {
          path: "/role_permission",
          component: role_permission,
        },
        // 权限-------------------------------------------------------
        {
          path: "/permission_list",
          component: permission_list,
        },
        {
          path: "/permission_details",
          component: permission_details,
        },
        {
          path: "/permission_add",
          component: permission_add,
        },
        // 系统用户---------------------------------------------------
        {
          path: "/sysUser_list",
          component: sysUser_list,
        },
        {
          path: "/sysUser_details",
          component: sysUser_details,
        },
        {
          path: "/sysUser_add",
          component: sysUser_add,
        },
        {
          path: "/institutions_editor",
          component: institutions_editor,
        },
        // 日志-------------------------------------------------------
        {
          path: "/operation_log",
          component: operation_log,
        },
        {
          path: "/login_log",
          component: login_log,
        },
        // 标签---------------------------------------------------
        {
          path: "/label_list",
          component: label_list,
        },
        {
          path: "/label_details",
          component: label_details,
        },
        {
          path: "/label_add",
          component: label_add,
        },
        // 新标签---------------------------------------------------
        {
          path: "/newLabel_list",
          component: newLabel_list,
        },
        {
          path: "/newLabel_editor1",
          component: newLabel_editor1,
        },
        {
          path: "/newLabel_editor2",
          component: newLabel_editor2,
        },
        {
          path: "/newLabel_editor3",
          component: newLabel_editor3,
        },
        {
          path: "/newLabel_editor4",
          component: newLabel_editor4,
        },
        // 服务---------------------------------------------------
        {
          path: "/release_service_list",
          component: release_service_list,
        },
        {
          path: "/release_service_details",
          component: release_service_details,
        },
        {
          path: "/release_service_editor",
          component: release_service_editor,
        },
        {
          path: "/release_service_add",
          component: release_service_add,
        },
        {
          path: "/release_service_push",
          component: release_service_push,
        },
        // 任务---------------------------------------------------
        {
          path: "/release_task_list",
          component: release_task_list,
        },
        {
          path: "/release_task_details",
          component: release_task_details,
        },
        {
          path: "/release_task_editor",
          component: release_task_editor,
        },
        {
          path: "/release_task_add",
          component: release_task_add,
        },
        {
          path: "/release_task_push",
          component: release_task_push,
        },
        // 问题---------------------------------------------------
        {
          path: "/release_problem_list",
          component: release_problem_list,
        },
        {
          path: "/release_problem_details",
          component: release_problem_details,
        },
        {
          path: "/release_problem_editor",
          component: release_problem_editor,
        },
        {
          path: "/release_problem_add",
          component: release_problem_add,
        },
        {
          path: "/release_problem_push",
          component: release_problem_push,
        },
        // 供应---------------------------------------------------
        {
          path: "/release_supply_list",
          component: release_supply_list,
        },
        {
          path: "/release_supply_details",
          component: release_supply_details,
        },
        {
          path: "/release_supply_editor",
          component: release_supply_editor,
        },
        {
          path: "/release_supply_add",
          component: release_supply_add,
        },
        {
          path: "/release_supply_push",
          component: release_supply_push,
        },
        // 求购---------------------------------------------------
        {
          path: "/release_demand_list",
          component: release_demand_list,
        },
        {
          path: "/release_demand_details",
          component: release_demand_details,
        },
        {
          path: "/release_demand_editor",
          component: release_demand_editor,
        },
        {
          path: "/release_demand_add",
          component: release_demand_add,
        },
        {
          path: "/release_demand_push",
          component: release_demand_push,
        },
        // 其他消息---------------------------------------------------
        {
          path: "/release_msg",
          component: release_msg,
        },
        // 教学---------------------------------------------------
        {
          path: "/teaching_list",
          component: teaching_list,
        },
        {
          path: "/teaching_details",
          component: teaching_details,
        },
        {
          path: "/teaching_editor",
          component: teaching_editor,
        },
        {
          path: "/teaching_add",
          component: teaching_add,
        },
        {
          path: "/teaching_push",
          component: teaching_push,
        },
        // 直播---------------------------------------------------
        {
          path: "/live_list",
          component: live_list,
        },
        {
          path: "/live_details",
          component: live_details,
        },
        {
          path: "/live_editor",
          component: live_editor,
        },
        {
          path: "/live_add",
          component: live_add,
        },
        // 直播---------------------------------------------------
        {
          path: "/push_list",
          component: push_list,
        },
        // 培训---------------------------------------------------
        {
          path: "/training_list",
          component: training_list,
        },
        //培训-老师
        {
          path: "/teacher_list",
          component: teacher_list,
        },
        {
          path: "/teacher_add",
          component: teacher_add,
        },
        //提现------------------------------------------------
        {
          path: "/withdrawal_list",
          component: withdrawal_list,
        },
        //开放平台------------------------------------------------
        {
          path: "/openUser",
          component: openUser,
        },
        {
          path: "/openService",
          component: openService,
        },
        {
          path: "/openServiceAdd",
          component: openServiceAdd,
        },
      ],
    },
  ],
});
router.beforeEach(function (to, from, next) {
//   console.log(to, from, next);
  if (to.path!='/login') {
    const userState = localStorage.getItem("userState");
    if (userState != 1) return next("/login");
    next();
  } else {
    next();
  }
});
export default router;
