<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/teaching_list' }"
              >教学列表</el-breadcrumb-item
            >
            <el-breadcrumb-item>添加教学</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">添加教学信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :label-position="labelPosition"
          :hide-required-asterisk="true"
        >
          <div class="grid-content">
            <el-form-item
              label="教学名称"
              :label-width="formLabelWidth"
              prop="teachName"
            >
              <el-input
                v-model="form.teachName"
                autocomplete="off"
                placeholder="请输入服务标题"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="教学类型" :label-width="formLabelWidth" prop="teachType">
              <el-input v-model="form.teachType" autocomplete="off" placeholder="请输入服务内容"></el-input>
            </el-form-item> -->
            <el-form-item
              label="教学简介"
              :label-width="formLabelWidth"
              prop="teachDesc"
            >
              <!-- <el-input v-model="form.teachDesc" autocomplete="off" placeholder="请输入教学简介"></el-input> -->
              <quill-editor
                ref="text"
                v-model="form.teachDesc"
                class="myQuillEditor"
                :options="editorOption"
              />
            </el-form-item>
            <el-form-item label="教学作者" :label-width="formLabelWidth">
              <el-select v-model="form.userId" placeholder="请选择">
                <el-option
                  v-for="(item, i) in options"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
                <!-- 分页 -->
                <el-pagination
                  small
                  @current-change="handleCurrentChange"
                  :current-page="params.pageNo"
                  :page-size="params.pageSize"
                  layout="prev, pager,next,total"
                  :total="total"
                  style="text-align: center;"
                  :hide-on-single-page="true"
                >
                </el-pagination>
              </el-select>
            </el-form-item>
            <el-form-item
              label="教学封面"
              :label-width="formLabelWidth"
              prop="teachHead"
            >
              <div>
                <el-upload
                  :action="action"
                  list-type="picture-card"
                  :on-success="success"
                  :limit="1"
                  :file-list="fileList"
                  :on-remove="remove"
                >
                  <el-button size="small" type="primary">添加图片</el-button>
                </el-upload>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      // 当前数据id
      id: "",
      // 表单信息
      form: {
        teachName: "",
        teachType: "0",
        teachHead: "",
        teachDesc: "",
        userId: "",
      },
      options: [],
      //图片
      fileList: [],
      // 表单信息验证项
      rules: {
        teachName: [
          { required: true, message: "请输入服务标题", trigger: "blur" },
        ],
        teachType: [
          { required: true, message: "请输入服务内容", trigger: "blur" },
        ],
        teachHead: [
          { required: true, message: "请上传教学封面", trigger: "blur" },
        ],
        teachDesc: [
          { required: true, message: "请输入教学简介", trigger: "blur" },
        ],
      },
      // 左侧标题宽度
      formLabelWidth: "120px",
      //左侧标题对齐
      labelPosition: "left",
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector("#upimg button").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      total:0,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.action = this.$http.defaults.baseURL + "/file/upLoadImage";
    this.getDetails();
  },
  methods: {
    // 分页-页码
    handleCurrentChange(val) {
      this.params.pageNo = val
      this.getDetails();
    },
    //获取
    async getDetails() {
      const { data: res } = await this.$http.get(
        "/admin/user/auth/getPageExperts",
        {
        params: {
         ...this.params
        }
      }
      );
      let data=[]
      res.data.rows.map((value, index, arry) => {
        data.push({ label: value.userName, value: value.userId });
      });
      this.options = data;
      this.total = res.data.totalRows
    },
    //提交
    submitForm(formName) {
      let dataImg = [];
      this.fileList.map((value, index, arry) => {
        dataImg.push(value.url);
      });
      this.form.teachHead = dataImg.toString();
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.putChange();
        }
      });
    },
    async putChange() {
      const { data: res } = await this.$http.post(
        "/admin/teach/add",
        this.form
      );
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1);
      }, 1000);
    },
    //图片上传成功后
    success(response, file, fileList) {
      this.fileList.push({ url: response.data });
    },
    //移除图片
    remove(file, fileList) {
      this.fileList = [];
      fileList.forEach((file) => {
        this.fileList.push({ url: file.url });
      });
    },
  },
};
// 富文本编辑器工具栏配置，因为涉及到富文本编辑器以外的组件调用，所以在这里我们需要重新自定义一下富文本编辑器的工具栏
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["link", "image", "video"],
  ["clean"], // remove formatting button
];
</script>
<style scoped lang="less"></style>