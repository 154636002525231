import { render, staticRenderFns } from "./u_training_user_detils.vue?vue&type=template&id=42664591&scoped=true"
import script from "./u_training_user_detils.vue?vue&type=script&lang=js"
export * from "./u_training_user_detils.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42664591",
  null
  
)

export default component.exports