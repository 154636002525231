<template>
  <el-container class="container">
    <el-aside :width="isCollapse ? '64px' : '250px'">
      <div class="toggle-button" @click="toggleCollapse">
        <div>
          <el-image :style="!isCollapse ? 'height: 40px' : 'height: 25px'" :src="logo" fit="cover"></el-image>
          <!-- <p v-if="!isCollapse">杨凌农业人力资源共享管理平台</p> -->
        </div>
      </div>
      <el-row>
        <el-col>
          <el-menu :default-active="activePath" class="el-menu-vertical-demo" unique-opened :collapse="isCollapse"
            :collapse-transition="false" router background-color="#001529" text-color="#A5ACB3"
            active-text-color="#1990FF">
            <template v-for="item in menuList">
              <template v-if="item.children">
                <el-submenu :index="item.id" :key="item.id">
                  <template slot="title">
                    <i :class="item.icon"></i>
                    <span slot="title">{{ item.title }}</span>
                  </template>
                  <el-menu-item :index="subItem.id" v-for="(subItem, i) in item.children" :key="i"
                    @click="saveNavState(subItem.id)">
                    {{ subItem.title }}
                  </el-menu-item>
                </el-submenu>
              </template>
            </template>
          </el-menu>
        </el-col>
      </el-row>
    </el-aside>
    <el-main class="main">
      <el-header height="80px" class="header">
        <i class="el-icon-user icon">&nbsp;this&nbsp;&nbsp;&nbsp;</i>
        <el-button type="text" style="margin-left:10px" @click="loginOut">退出登录</el-button>
      </el-header>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>



<script>
export default {
  data() {
    return {
      logo: require('@/assets/logo.jpg'),
      isCollapse: false,
      activePath: '/index',
      menuList: [
        {
          id: '/indexs',
          icon: 'el-icon-s-data',
          title: "首页",
          children: [
            {
              id: '/index',
              title: '数据看板',
            },
          ]
        },
        {
          id: '/list',
          icon: 'el-icon-s-platform',
          title: "用户管理",
          children: [
            {
              id: '/ulist',
              title: '用户列表',
            },
            {
              id: '/label_list',
              title: '单位列表',
            },
            {
              id: '/newLabel_list',
              title: '新标签列表',
            },
            {
              id: '/newLabel_editor4',
              title: '社会化服务标签',
            }
          ]
        },
        {
          id: '/permissions',
          icon: 'el-icon-s-tools',
          title: "系统管理",
          children: [
            {
              id: '/permission_list',
              title: '系统权限',
            },
            {
              id: '/role_list',
              title: '系统角色',
            },
            {
              id: '/sysUser_list',
              title: '系统用户',
            },
          ]
        },
        {
          id: '/release',
          icon: 'el-icon-circle-plus',
          title: "发布管理",
          children: [
            {
              id: '/release_service_list',
              title: '服务列表',
            },
            {
              id: '/release_task_list',
              title: '任务列表',
            },
            {
              id: '/release_problem_list',
              title: '问题列表',
            },
            {
              id: '/release_supply_list',
              title: '供应列表',
            },
            {
              id: '/release_demand_list',
              title: '采购列表',
            },
            {
              id: '/release_msg',
              title: '导入消息',
            },
          ]
        },
        {
          id: '/teaching',
          icon: 'el-icon-video-camera-solid',
          title: "教学管理",
          children: [
            {
              id: '/teaching_list',
              title: '教学列表',
            },
          ]
        },
        {
          id: '/live',
          icon: 'el-icon-camera-solid',
          title: "直播管理",
          children: [
            {
              id: '/live_list',
              title: '直播列表',
            },
          ]
        },
        // {
        //   id: '/push',
        //   icon: 'el-icon-upload',
        //   title: "推送管理",
        //   children: [
        //     {
        //       id: '/push_list',
        //       title: '推送列表',
        //     },
        //   ]
        // },
        // {
        //   id: '/training',
        //   icon: 'el-icon-s-custom',
        //   title: "培训机构管理",
        //   children: [
        //     {
        //       id: '/training_list',
        //       title: '培训管理',
        //     },
        //     {
        //       id: '/teacher_list',
        //       title: '讲师管理',
        //     },
        //   ]
        // },
        {
          id: '/log',
          icon: 'el-icon-s-order',
          title: "日志管理",
          children: [
            {
              id: '/login_log',
              title: '登录日志',
            },
            {
              id: '/operation_log',
              title: '操作日志',
            },
          ]
        },
        {
          id: '/withdrawal',
          icon: 'el-icon-bank-card',
          title: "提现管理",
          children: [
            {
              id: '/withdrawal_list',
              title: '提现列表',
            }
          ]
        },
        {
          id: '/openPlatform',
          icon: 'el-icon-s-promotion',
          title: "开放平台",
          children: [
            {
              id: '/openUser',
              title: '用户列表',
            },
            {
              id: '/openService',
              title: '服务接口',
            }
          ]
        },
      ]
    }
  },
  methods: {
    // 点击退出按钮
    loginOut() {
      this.loginOuts()
    },
    async loginOuts() {
      window.sessionStorage.clear()
      const { data: res } = await this.$http.get("/logout")
      this.$message.success(res);
      this.$router.push("/login")
      localStorage.removeItem('userState');
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
    },
    // 点击按钮切换菜单的折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    }
  },
  created() {
    this.activePath = window.sessionStorage.getItem('activePath')
  }
}
</script>



<style scoped lang="less">
.container {
  height: 100vh;

  .header {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    line-height: 80px;
    box-shadow: 0px 0px 20px 1px #eee;
    text-align: right;

    .icon {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .main {
    background: #f0f0f0;
    position: relative;
    padding-top: 100px;
  }
}

//侧边栏选中背景色
.el-aside {
  background: #001529;
  // height: 100vh;
  overflow: hidden;

  .el-menu {
    border-right: none;
  }

  // 侧边栏展开
  .toggle-button {
    background-color: #002140;
    font-size: 12px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.2em;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    p {
      margin-top: 5px;
    }
  }
}
</style>