import { render, staticRenderFns } from "./release_demand_details.vue?vue&type=template&id=44ecad5a&scoped=true"
import script from "./release_demand_details.vue?vue&type=script&lang=js"
export * from "./release_demand_details.vue?vue&type=script&lang=js"
import style0 from "./release_demand_details.vue?vue&type=style&index=0&id=44ecad5a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ecad5a",
  null
  
)

export default component.exports