<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>用户列表</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">用户列表信息</div>
          <el-form :inline="true" :model="form" class="demo-form-inline">
            <el-form-item label="用户姓名：">
              <el-input
                v-model="form.userName"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户手机：">
              <el-input
                v-model="form.userPhone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select v-model="isPending" placeholder="请选择">
                <el-option label="已认证" value="2"></el-option>
                <el-option label="未审核" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="认证类型：">
              <el-select v-model="form.authType" placeholder="请选择">
                <el-option label="农业技术员" value="1"></el-option>
                <el-option label="专业农服队" value="2"></el-option>
                <el-option label="农业专家" value="3"></el-option>
                <el-option label="农贸服务商" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
              <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <!-- <el-row
        class="operation"
        :gutter="20"
        style="display: flex; align-items: center"
      >
        <el-col :span="4">
          <el-input
            v-model="name"
            placeholder="搜索用户"
            @input="change"
          ></el-input>
        </el-col>
        <el-col :span="2">
          <el-checkbox v-model="checked" @change="onChangeChecked"
            >只显示待审核</el-checkbox
          >
        </el-col>
      </el-row> -->
      <!-- <div style="color:#000;font-size: 16px;margin-bottom: 20px;">用户列表</div> -->
      <div class="table-tips" v-if="count > 0">
        <i class="el-icon-info"></i>
        <div>
          您有新的审核信息<span>{{ count }}</span
          >条
        </div>
      </div>
      <el-table
        :data="List"
        border
        height="520"
        @sort-change="onChangeSortable"
        @filter-change="handleFilterChange"
      >
        <el-table-column type="index" label="序号">
          <template slot-scope="scope">
            {{ (form.pageNo - 1) * form.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>

        <el-table-column label="用户昵称">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.userMiniNick == 'undefined' ||
                scope.row.userName == ''
              "
            ></span
            ><span v-else>{{ scope.row.userMiniNick }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="姓名"> </el-table-column>
        <el-table-column prop="userPhone" label="用户手机"> </el-table-column>
        <el-table-column prop="createTime" label="注册时间" sortable>
        </el-table-column>
        <el-table-column label="认证类别">
          <template slot-scope="scope">
            <el-link
              type="success"
              v-if="scope.row.selfStatus == 1"
              :underline="false"
              >农业技术&nbsp;</el-link
            >
            <el-link
              type="success"
              v-if="scope.row.teamStatus == 1"
              :underline="false"
              >专业农服队&nbsp;</el-link
            >
            <el-link
              type="success"
              v-if="scope.row.expertStatus == 1"
              :underline="false"
              >农业专家&nbsp;</el-link
            >
            <el-link
              type="success"
              v-if="scope.row.marketStatus == 1"
              :underline="false"
              >农贸服务商&nbsp;</el-link
            >
            <el-link
              type="success"
              v-if="scope.row.assetStatus == 1"
              :underline="false"
              >农资服务商&nbsp;</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          :filters="[
            { text: '未审核', value: '1' },
            { text: '已认证', value: '2' },
          ]"
          column-key="keyStatus"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.selfStatus == 0 ||
                scope.row.teamStatus == 0 ||
                scope.row.expertStatus == 0 ||
                scope.row.marketStatus == 0 ||
                scope.row.assetStatus == 0
              "
              style="display: flex; align-items: center"
            >
              <div
                style="
                  background: rgba(255, 87, 51, 1);
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  margin-right: 6px;
                "
              ></div>
              待审核
            </div>
            <div
              v-else-if="
                scope.row.selfStatus == 1 ||
                scope.row.teamStatus == 1 ||
                scope.row.expertStatus == 1 ||
                scope.row.marketStatus == 1 ||
                scope.row.assetStatus == 1
              "
              style="display: flex; align-items: center"
            >
              <div
                style="
                  background: rgba(24, 144, 255, 1);
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  margin-right: 6px;
                "
              ></div>
              已认证
            </div>
          </template>
        </el-table-column>
        <el-table-column label="分享链接" width="300px">
          <template slot-scope="scope">
            /pagesSub/talent/talentDetail?id={{ scope.row.userId }}
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="审核时间"
          sortable
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="review(scope.row.userId)"
              v-if="
                scope.row.selfStatus == 0 ||
                scope.row.teamStatus == 0 ||
                scope.row.expertStatus == 0 ||
                scope.row.marketStatus == 0 ||
                scope.row.assetStatus == 0
              "
              :underline="false"
              >待审核</el-button
            >
            <el-button type="text" @click="review(scope.row.userId)" v-else
              >查看</el-button
            >
            <el-button type="text" @click="push(scope.row.userId)"
              >推送</el-button
            >
            <!-- <el-button type="text" @click="toMsg(scope.row.userId)"
              >消息</el-button
            > -->
            <!-- <el-popconfirm title="确定删除此条数据吗？" @confirm='deletes(scope.row.userId)'>
              <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
            </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNo"
        background
        :page-size="form.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        style="text-align: right"
      >
      </el-pagination>
    </div>
  </div>
</template>



<script>
// import { filter } form 'vue/types/umd';
export default {
  data() {
    return {
      // 总条数
      total: 0,
      // 列表
      List: [],
      checked: false,
      isPending:'',
      form: {
        userName: "",
        pageNo: 1,
        pageSize: 50,
        isPending: "",
        isAscByCreateTime: 0,
        authType: "",
        userPhone: "",
      },
      count: 0,
    };
  },
  created() {
    this.getList();
    this.getCount();
  },
  methods: {
    handleFilterChange(val) {
      const filterval=val.keyStatus.toString()
      if(filterval==1){
        this.form.isPending = 1
        this.getList()
      }else if(filterval==2){
        this.form.isPending = 2
        this.getList()
      }else{
        this.form.isPending = 0
        this.getList()
      }
    },
    resetForm() {
      this.form.userName = "";
      this.form.userPhone = "";
      this.form.isPending = "";
      this.form.authType = "";
      this.isPending=''
    },
    onSubmit() {
      this.form.isPending=this.isPending
      this.getList();
    },
    onChangeSortable(e) {
      if (e.order == "descending" || e.order == null) {
        this.form.isAscByCreateTime = 0;
        this.getList();
      } else {
        this.form.isAscByCreateTime = 1;
        this.getList();
      }
    },
    // onChangeChecked(e) {
    //   if (e == true) {
    //     this.form.isPending = 1;
    //     this.getList();
    //   } else {
    //     this.form.isPending = 0;
    //     this.getList();
    //   }
    // },
    async getCount() {
      const { data: res } = await this.$http.get(
        "/admin/user/getCountByAuthStatus",
        {
          params: {
            isPending: 1,
          },
        }
      );
      this.count = res.data;
    },
    // 列表
    async getList() {
      const { data: res } = await this.$http.get("/admin/user/pageWithStatus", {
        params: {
          ...this.form,
        },
      });
      this.List = res.data.rows;
      this.total = res.data.totalRows;
    },
    //删除
    deletes(id) {
      this.remove(id);
    },
    async remove(id) {
      const { data: res } = await this.$http.post("/admin/user/delete", {
        userId: id,
      });
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList();
    },
    //编辑
    review(id) {
      this.$router.push({
        path: "/udetails",
        query: {
          id: id,
        },
      });
    },
    //推送
    push(id) {
      this.$router.push({
        path: "/upush",
        query: {
          id: id,
        },
      });
    },
    //添加
    tebleAdd() {
      this.$router.push({
        path: "/uadd",
      });
    },
    //聊天
    toMsg(id) {
      this.$router.push({
        path: "/uMsg",
        query: {
          id: id,
        },
      });
    },
    //搜索
    change(e) {
      this.name = e;
      this.getList();
    },
    // 分页-数量
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getList();
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.form.pageNo = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="less">
.title {
  margin-bottom: 20px;
}
.table-tips {
  display: flex;
  align-items: center;
  padding-left: 23.24px;
  background: rgba(230, 247, 255, 1);
  border: 1px solid rgba(186, 231, 255, 1);
  height: 40px;
  font-size: 14px;
  margin-bottom: 16px;
  .el-icon-info {
    color: rgba(24, 144, 255, 1);
    margin-right: 12px;
  }
  span {
    color: rgba(24, 144, 255, 1);
    font-weight: 500;
  }
}
</style>