<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/release_supply_list' }">供应列表</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/release_supply_details?id=' + id }">供应管理</el-breadcrumb-item>
            <el-breadcrumb-item>供应编辑</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">编辑供应信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="产品名称" :label-width="formLabelWidth" prop="supplyName">
              <el-input v-model="form.supplyName" autocomplete="off" placeholder="请输入产品名称"></el-input>
            </el-form-item>
            <el-form-item label="产品品种" :label-width="formLabelWidth" prop="supplyBreed">
              <el-input v-model="form.supplyBreed" autocomplete="off" placeholder="请输入产品品种"></el-input>
            </el-form-item>
            <el-form-item label="供应量" :label-width="formLabelWidth" prop="supplyNum">
              <el-input v-model="form.supplyNum" type="number" autocomplete="off" placeholder="请输入供应量"></el-input>
            </el-form-item>
            <el-form-item label="供应单位" :label-width="formLabelWidth" prop="supplyUnit">
              <el-select v-model="form.supplyUnit" placeholder="请选择" @change="change">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="供应开始时间" :label-width="formLabelWidth" prop="startTime">
              <el-date-picker v-model="form.startTime" type="datetime" placeholder="请选择供应开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="供应结束时间" :label-width="formLabelWidth" prop="endTime">
              <el-date-picker v-model="form.endTime" type="datetime" placeholder="请选择供应结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="供应介绍" :label-width="formLabelWidth" prop="userCompany">
              <div>
                <el-upload :action="action" list-type="picture-card" :on-success="success" :limit="3"
                  :file-list="fileList" :on-remove="remove">
                  <el-button size="small" type="primary">添加图片</el-button>
                </el-upload>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前数据id
      id: '',
      // 表单信息
      form: {},
      //图片
      fileList: [],
      // 表单信息验证项
      rules: {
        supplyName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' },
        ],
        supplyBreed: [
          { required: true, message: '请输入产品品种', trigger: 'blur' },
        ],
        supplyNum: [
          { required: true, message: '请输入供应量', trigger: 'blur' },
        ],
        startTime: [
          { required: true, message: '请选择供应开始时间', trigger: 'blur' },
        ],
        endTime: [
          { required: true, message: '请选择供应结束时间', trigger: 'blur' },
        ],
        supplyUnit: [
          { required: true, message: '请选择供应单位', trigger: 'blur' },
        ],
      },
      //单位选择
      options: [{
        value: '亩',
        label: '亩'
      }, {
        value: '次',
        label: '次'
      }, {
        value: '天',
        label: '天'
      }, {
        value: '人',
        label: '人'
      },
      {
        value: '吨',
        label: '吨'
      }, {
        value: '斤',
        label: '斤'
      }, {
        value: '公斤',
        label: '公斤'
      }],
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left',
    }
  },
  created() {
    this.id = this.$route.query.id
    this.action = this.$http.defaults.baseURL + '/file/upLoadImage'
    this.getDetails()
  },
  methods: {
    //选择单位
    change(e) {
      this.form.supplyUnit = e
    },
    //获取
    async getDetails() {
      const { data: res } = await this.$http.get("/admin/supply/detail", {
        params: {
          supplyId: this.id
        }
      })
      this.form = res.data
      if (res.data.pics != '') {
        let img = res.data.pics;
        let data = [];
        img.map((value, index, arry) => {
          data.push({ url: value });
        });
        this.fileList = data
      } else {
        this.fileList = []
      }
    },
    //提交
    submitForm(formName) {
      let dataImg = []
      this.fileList.map((value, index, arry) => {
        dataImg.push(value.url);
      });
      this.form.pics = dataImg
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.putChange()
        }
      });
    },
    async putChange() {
      const { data: res } = await this.$http.post("/admin/supply/edit", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
    //图片上传成功后
    success(response, file, fileList) {
      this.fileList.push({ url: response.data })
    },
    //移除图片
    remove(file, fileList) {
      this.fileList = []
      fileList.forEach(file => {
        this.fileList.push({ url: file.url });
      });
    },
  }
}
</script>
<style scoped lang="less">

</style>