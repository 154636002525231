<template>
  <div class="body">
    <video src="../../assets/img/loginBg.mp4" autoplay muted></video>
    <div class="login">
      <el-image
        src="https://video.ddbangnong.com/logo.png"
        style="width: 200px"
      ></el-image>
      <div class="login-top">
        <h1>杨陵农业人力资源共享服务平台</h1>
        <el-form
          class="login_form"
          :model="loginForm"
          :label-position="labelPosition"
          :rules="loginFormRules"
          ref="loginFormRef"
          hide-required-asterisk
        >
          <el-form-item prop="username" label="账号" class="item">
            <el-input
              v-model="loginForm.username"
              class="borderNone"
              prefix-icon="el-icon-message"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码" class="item">
            <el-input
              v-model="loginForm.password"
              type="password"
              class="borderNone"
              prefix-icon="el-icon-unlock"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="forgot">
          <el-button type="primary" @click="login" class="btn">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Base64 } from "js-base64";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      labelPosition: "top",
      loginForm: {
        username: "",
        password: "",
      },
      loginFormRules: {
        username: [
          { required: true, message: "请输入账户名称", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.login();
        return false;
      }
    };
  },
  methods: {
    encryptByEnAES(plaintext) {
      const AES_KEY = "2B7E151628AED2A6ABF7158809CF4F3C"; // 128位
      const IV_KEY = "0000000000000000"; // 偏移量
      // const plaintext = "冯子英"; // 要加密的明文
      const key = CryptoJS.enc.Utf8.parse(AES_KEY); // 密钥，长度为 16 字节（128 bits）
      const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
        mode: CryptoJS.mode.CBC, // 加密模式
        padding: CryptoJS.pad.ZeroPadding, // 填充模式
        iv: CryptoJS.enc.Utf8.parse(IV_KEY),
      });
      const ciphertext = encrypted.toString(); // 加密后的密文
      console.log(ciphertext);
      return ciphertext;
    },
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        // const password = Base64.encode(this.loginForm.password);
        const password = this.encryptByEnAES(this.loginForm.password);
        const { data: res } = await this.$http.post(
          "/login?username=" + this.loginForm.username + "&password=" + password
        );
        if (res.code != 200) return this.$message.error(res.message);
        this.$message.success(res.message);
        localStorage.setItem("userState", 1);
        if (res.data.isOrg != 1) {
          this.$router.push("/home");
        } else {
          this.$router.push("/orgHome");
          this.$store.commit("updateData", res.data.organization);
          window.sessionStorage.setItem(
            "organizationId",
            res.data.organization.organizationId
          );
        }
      });
    },
    reset() {
      this.$refs.loginFormRef.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  // background: url(../../assets/img/login/banner.jpg) repeat;
  font-family: "Roboto", sans-serif;
  font-size: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  transform: scale(1.2);
}

.login {
  width: 25%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 50px 10px;
  border-radius: 20px;
}

.login-top {
  padding: 0 40px;
  margin-top: 30px;
  text-align: left;
}

.login-top h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  margin: 0px auto 20px;
  width: 70%;
  line-height: 40px;
  letter-spacing: 1px;
}

.forgot {
  text-align: right;
  padding-top: 20px;

  .btn {
    width: 100%;
  }
}

// .el-textarea__inner,
// .el-input__inner {
//   background: transparent !important;
//   color: #fff;
//   border: 0;
// }

/deep/ .el-form-item__label {
  color: #fff;
  font-size: 18px;
  padding: 0;
}

/deep/.el-input__inner {
  height: 52px;
  background: rgba(0, 0, 0, 0.7);
  // 设置字号
  font-size: 14px;
  font-weight: 400;
  // 设置输入字体的颜色
  color: #fff;
  border-color: #363739;
}
</style>
